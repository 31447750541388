import { useParams } from "react-router-dom";
import styles from "./success.module.css";
import MinfertLogo from "../../../assets/images/logo.webp";
export default function SuccessPayU() {
  const { txnid } = useParams();

  return (
    <div className={styles.container}>
      <div className={styles.transation}>
        <img
          src={MinfertLogo}
          width="175"
          height="56"
          alt="Minfert"
        />
        <p className={styles.status}>
          <strong>Transaction Successful!</strong>
        </p>
        <div className={styles.content}>
          {" "}
          Your Transaction ID is {txnid}. Thank you for your payment.
        </div>

        <button className={styles.button} onClick={() => window.location.href = process.env.REACT_APP_REDIRECT_URL_AFTER_PAYMENT}>
  Go to Home
</button>
      </div>
    </div>
  );
}
