import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import TopNavBar from "../../layouts/TopNavBar";
import AlertContext from "../../../../context/Alert/AlertContext";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import classes from "./addFrequentProductStyle.module.css";
import { errorToast } from "../../common/Toaster";
import { GrAddCircle, GrSubtractCircle } from "react-icons/gr";
import AuthContext from "../../../../context/Auth/AuthContext";
import LoaderContext from "../../../../context/Loader/LoaderContext";

// Validation rules
const validationRules = {
  product_id: Yup.string().required("Product is required"),
  fbt: Yup.array()
    .of(
      Yup.object().shape({
        frequent_product_id: Yup.string().required(
          "Frequent product is required"
        ),
        product_variants_id: Yup.string().required(
          "Product variant is required"
        ),
      })
    )
    .min(1, "At least one row is required"),
};

const AddFrequentProduct = () => {
  const { setAlertMessage } = useContext(AlertContext);
  const { authPermissions } = useContext(AuthContext);
  const navigate = useNavigate();
  const { setLoaderCheck } = useContext(LoaderContext);
  const [products, setProducts] = useState([]);
  const [fbt, setFbt] = useState([
    { frequent_product_id: "", product_variants_id: "", variants: [] },
  ]);

  const { id } = useParams();

  // Get Product List
  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get("products")
      .then((response) => {
        setLoaderCheck(false);
        setProducts(response?.data?.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
        errorToast(error.message);
      });
  }, []);

  useEffect(() => {
    if (id) {
      setLoaderCheck(true);
      axiosPrivate
        .get(`fbt/${id}`)
        .then((response) => {
          setLoaderCheck(false);
  
          // Ensure the entire structure remains intact
          const finalData = response?.data?.data.map((item) => {
            return {
              ...item, // Keep the original object structure
              fbt: {
                ...item.fbt, // Keep the original fbt object
                frequent_products: item.fbt?.frequent_products?.map((product) => ({
                  ...product, // Spread the original product object
                  variants: product.variants?.map((variant) => ({
                    ...variant, // Spread the original variant object
                    variant: variant?.variants, // Add the new "variant" field
                  })),
                })),
              },
            };
          });
          formik.setFieldValue("product_id", finalData[0]?.product_id)
          formik.setFieldValue("fbt", finalData[0]?.fbt?.frequent_products)
        })
        .catch((error) => {
          setLoaderCheck(false);
          errorToast(error.message);
        });
    }
  }, [id]);
  

  const getProductVariant = (id, index) => {
    axiosPrivate
      .get(`products/${id}`)
      .then((response) => {
        setLoaderCheck(false);
        const variants = response?.data?.data?.product_variants || [];
        // Update the 'variants' field in the specific row of fbt
        formik.setFieldValue(`fbt[${index}].variants`, variants);
      })
      .catch((error) => {
        errorToast(error.message);
      });
  };

  // Formik setup
  const formik = useFormik({
    initialValues: {
      product_id: "",
      fbt: fbt,
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: (values) => {
      const requetPayload = {
        product_id: values.product_id,
        fbt: values.fbt,
      };
      setLoaderCheck(true);
      if(id){
        axiosPrivate
        .put(`fbt/update/${id}`, requetPayload)
        .then((response) => {
          setLoaderCheck(false);
          setAlertMessage({
            message: "Frequent product updated successfully",
          });
          navigate(`/admin/frequently-products`);
        })
        .catch((error) => {
          setLoaderCheck(false);
          if (error.response) {
            if (error.response.data.errors.length === undefined) {
              errorToast(error.response.data.errors.msg);
            } else {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                errorToast(error.response.data.errors[i].msg);
              }
            }
          }
        });

      }else{
        axiosPrivate
        .post("fbt", requetPayload)
        .then((response) => {
          setLoaderCheck(false);
          setAlertMessage({
            message: "New frequent product created successfully",
          });
          navigate(`/admin/frequently-products`);
        })
        .catch((error) => {
          setLoaderCheck(false);
          if (error.response) {
            if (error.response.data.errors.length === undefined) {
              errorToast(error.response.data.errors.msg);
            } else {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                errorToast(error.response.data.errors[i].msg);
              }
            }
          }
        });
      }
    },
  });

  const addRow = () => {
    const newRow = {
      frequent_product_id: "",
      product_variants_id: "",
      variants: [],
    };
    // Directly update Formik's fbt using setFieldValue
    formik.setFieldValue("fbt", [...formik.values.fbt, newRow]);
  };

  const removeRow = (index) => {
    const newfbt = formik.values.fbt.filter((_, i) => i !== index);
    // Directly update Formik's fbt using setFieldValue
    formik.setFieldValue("fbt", newfbt);
  };

  return (
    <>
      <TopNavBar
        links={{
          list: "/admin/frequently-products",
          add: "/admin/frequently-products/add",
        }}
      />
      <div className="section-body">
        <div className="container-fluid">
          <div className="tab-content">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="product-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-header">
                    <strong>
                      {id ? "Edit Frequent Product" : "Add Frequent Product"}
                    </strong>
                  </div>
                  <div className="card-body">
                    <div className="row clearfix">
                      <div className="col-md-5 col-sm-12">
                        <div className="form-group">
                          <label className="form-label">
                            Product <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            id="productId"
                            name="product_id"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.product_id}
                          >
                            <option>Select Product</option>
                            {products?.map((product, key) => (
                              <option
                                key={key + product?.name}
                                value={product.id}
                              >
                                {product?.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.product_id &&
                          formik.errors.product_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.product_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Table data */}
                      {formik?.values?.fbt.map((row, index) => (
                        <div key={index} className="col-md-12 mb-3">
                          <div className="form-row">
                            <div className="col-md-5 col-sm-12">
                              <label className="form-label">
                                Frequent Product{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                name={`fbt[${index}].frequent_product_id`}
                                className="form-control"
                                value={row.frequent_product_id}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  getProductVariant(e.target.value, index);
                                }}
                                onBlur={formik.handleBlur}
                              >
                                <option>Select Frequent Product</option>
                                {products?.map((product) => (
                                  <option key={product.id} value={product.id}>
                                    {product.name}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.fbt?.[index]
                                ?.frequent_product_id &&
                                formik.errors.fbt?.[index]
                                  ?.frequent_product_id && (
                                  <div className={classes.invalidDataError}>
                                    {
                                      formik.errors.fbt[index]
                                        .frequent_product_id
                                    }
                                  </div>
                                )}
                            </div>

                            <div className="col-md-5 col-sm-12">
                              <label className="form-label">
                                Product Variant{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                name={`fbt[${index}].product_variants_id`}
                                className="form-control"
                                value={row.product_variants_id}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              >
                                <option>Select Product Variant</option>
                                {row?.variants.map((variant) => (
                                  <option key={variant.id} value={variant.id}>
                                    {variant?.variant}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.fbt?.[index]
                                ?.product_variants_id &&
                                formik.errors.fbt?.[index]
                                  ?.product_variants_id && (
                                  <div className={classes.invalidDataError}>
                                    {
                                      formik.errors.fbt[index]
                                        ?.product_variants_id
                                    }
                                  </div>
                                )}
                            </div>

                            <div className={classes.actionBtns}>
                              <label className="form-label">&nbsp;</label>
                              <button
                                className={`btn ${classes.addBtn}`}
                                onClick={addRow}
                              >
                                <GrAddCircle />
                              </button>
                              {formik.values.fbt.length > 1 && (
                                <button
                                  className={`btn ${classes.removeBtn}`}
                                  onClick={() => removeRow(index)}
                                >
                                  <GrSubtractCircle />
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="col-12 text-right">
                        <hr className="mt-4" />
                        <button
                          type="button"
                          id="button_1"
                          className="btn btn-secondary mx-1"
                          data-dismiss="modal"
                          onClick={() => navigate("/admin/frequently-products")}
                        >
                          CLOSE
                        </button>
                        {authPermissions.includes("Product-create") && (
                          <button
                            type="submit"
                            id="button_2"
                            className="btn btn-primary"
                          >
                            SUBMIT
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFrequentProduct;
