import React, { Fragment, useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/Auth/AuthContext";
import axiosApiSigninInstance from "../../hooks/axiosLogin";
import axiosPrivate from "../../hooks/axiosPrivate";
import AlertContext from "../../context/Alert/AlertContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./Auth.css";
import { showErrorToast } from "../../Toster";
import CartCountContext from "../../context/CartCount/CartCountContext";
import { FaEye } from "react-icons/fa6";
import { FaEyeSlash } from "react-icons/fa";

const SignIn = ({ isCart = null }) => {
  const { user, setUser } = useContext(AuthContext);
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const { cartCountCheck, setCartCountCheck, setCartCount } =
    useContext(CartCountContext);

  const loginSchema = Yup.object({
    email: Yup.string().required("Email is Required"),
    password: Yup.string().required("Password is Required"),
  });

  const loginForm = {
    email: "",
    password: "",
  };
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const addToCart = async (userId, cartItems) => {
    await cartItems?.map(async (item, index) => {
      let object = {
        product_id: item?.product_id,
        variant_id: item?.variant_id,
        quantity: item?.quantity,
        user_id: userId,
      };

      await axiosPrivate
        .post("/carts/create", object)
        .then((res) => {
          localStorage.removeItem("cart_items");
        })
        .catch((error) => {});
    });

    setCartCountCheck(!cartCountCheck);
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues: loginForm,
    validationSchema: loginSchema,
    onSubmit: async (data) => {
      axiosApiSigninInstance
        .post("login", data)
        .then(async (res) => {
          localStorage.setItem("userData", JSON.stringify(res.data));
          const cartItems = JSON.parse(localStorage.getItem("cart_items"));
          addToCart(res?.data?.userId, cartItems);
          setUser(res.data);
          if (res.data.role === "client") {
            if (isCart) {
              navigate("/checkout");
            } else {
              navigate("/user-dashboard");
            }
          } else {
            navigate("/admin");
          }
        })
        .catch((err) => {
          showErrorToast(
            err?.response?.data?.message ||
              err?.response?.data?.errors?.[0]?.msg
          );
        });
    },
  });

  useEffect(() => {
    if (user.token && user.role !== "client") {
      navigate("/admin", { replace: true });
    }
  }, []);

  // alert
  useEffect(() => {
    if (alertMessage != null) {
      toast.success(alertMessage.message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  const numberFilter = (event) => {
    const inputValue = event.target.value;
    const numericValue = parseInt(inputValue.replace(/\D/g, ""), 10);
    setFieldValue(event.target.name, numericValue);
  };

  return (
    <Fragment>
      <div className={isCart ? "auth_left_cart" : "auth_left"}>
        <div className="card">
          <div className="text-center mb-2">
            <Link className="header-brand" to="/">
              {/* <i className="fe fe-command brand-logo" /> */}
              <img
                src="./../assets/images/home/logo.webp"
                alt="login"
                loading="lazy"
              />
            </Link>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="card-title" style={{ textTransform: "none" }}>
                Login to your account
              </div>
              <div className="form-group">
                <label className="form-label">
                  Email<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="form-control"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Email"
                />
                {touched.email && errors.email ? (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors.email}
                  </div>
                ) : null}
              </div>
              <div style={{ position: "relative" }} className="form-group">
                <label className="form-label">
                  Password<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  // type="text"
                  name="password"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Password"
                />
                <div
                  style={{ position: "absolute", right: "5px", bottom: "5px" }}
                  onClick={togglePassword}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
                {touched.password && errors.password ? (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors.password}
                  </div>
                ) : null}
              </div>
              <div className="form-footer">
                <input
                  type="submit"
                  className="btn  btn-block submit-button"
                  value="Login"
                />
              </div>
              <div className="text-center text-muted mt-3">
                Don't have account? <Link to="/auth/signup">Sign Up</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SignIn;
