import React, { useState, useEffect } from "react";
import styles from "./ProductListing.module.css";
import { BsCurrencyRupee } from "react-icons/bs";
import { TbGardenCart } from "react-icons/tb";
import { FaBookmark } from "react-icons/fa";

import { Dropdown } from "primereact/dropdown";
import { Link, useParams } from "react-router-dom";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { useNavigate } from "react-router-dom";
import AlertContext from "../../../context/Alert/AlertContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import CartCountContext from "../../../context/CartCount/CartCountContext";
import { errorToast, successToast } from "../../backend/common/Toaster";
import LoaderContext from "../../../context/Loader/LoaderContext";
import { data } from "jquery";
import amazonImg from "../../../assets/images/amazon_new.webp";
import flipkartImg from "../../../assets/images/flipkart.png";
import amazonMobileImg from "../../../assets/images/AmazonMobilePNG.png";
import flipkartMobileImg from "../../../assets/images/flipkartMobile.png";
import { Helmet } from 'react-helmet';
function KitchenTerraceProducts() {
  const { slug } = useParams();
  const [selectedVarient, setSelectedVarient] = useState(null);
  const [product, setProduct] = useState([]);
  const [alertMessage, setAlertMessage] = useState(AlertContext);
  const navigate = useNavigate();
  const { cartCountCheck, setCartCountCheck } = useContext(CartCountContext);
  const { setLoaderCheck } = useContext(LoaderContext);
  const [categoryData, setCategoryData] = useState([]);
  if (localStorage.getItem("userData")) {
    const userData = JSON.parse(localStorage.getItem("userData"));
    var { userId } = userData;
  }

  useEffect(() => {
    axiosPrivate
      .get("categories")
      .then((res) => {
        setCategoryData(res?.data?.data);
      })
      .catch((err) => console.log());
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    const options = {
      headers: { "content-type": "application/json" },
    };
    setLoaderCheck(true);
    axiosPrivate
      .get("products", options)
      .then((products) => {
        setLoaderCheck(false);
        setProduct(products?.data?.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
      });
  }, []);

  function handleChange(slug, e) {
    const id = e.target.value;
    let product_slug = productslug(slug);
    setSelectedVarient(e.target.value);
    navigate(`/product-detail/${product_slug}/${id}`);
  }

  function productslug(slug) {
    return slug
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "");
  }

  function addToCart(productDetail, string) {
    let userlogin = localStorage.getItem("userData");
    let object = {
      product_id: productDetail.id,
      variant_id: productDetail.product_variants[0].id,
      quantity: 1,
    };

    if (userlogin) {
      setLoaderCheck(true);
      axiosPrivate
        .post("/carts/create", object)
        .then((res) => {
          setLoaderCheck(false);
          window.scroll({
            top: 0,
            behavior: "smooth",
          });
          // debugger;
          successToast("Item Added in Cart!");
          // setAlertMessage({ message: "Item Added in Cart!" });
          if (string === "buynow") {
            navigate("/cart");
          }
        })
        .catch((error) => {
          setLoaderCheck(false);
          errorToast("Something Went Wrong!");
          setAlertMessage({ message: "Something Went Wrong!" });
          window.scroll({
            top: 0,
            behavior: "smooth",
          });
        });
    } else {
      object["product_name"] = productDetail.name;
      object["rate"] = productDetail.product_variants[0].regular_price;
      object["base_unit"] = productDetail.product_variants[0].base_unit;
      object["variant"] = productDetail.product_variants[0].variant;
      object["description"] = productDetail.description;
      object["image"] = productDetail.product_images[0].image;
      let cart_items = localStorage.getItem("cart_items");
      let cart_array = [];
      cart_array.push(object);
      if (!cart_items) {
        localStorage.setItem("cart_items", JSON.stringify(cart_array));
      } else {
        let cart_array = JSON.parse(cart_items);
        let boolean_cart = false;
        cart_array?.forEach((element) => {
          if (object.variant_id == element.variant_id) {
            boolean_cart = true;
            element.quantity = element.quantity + 1;
          }
        });

        if (!boolean_cart) {
          cart_array.push(object);
        }

        localStorage.setItem("cart_items", JSON.stringify(cart_array));
      }
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
      // debugger;
      successToast("Item Added in Cart!");
      // setAlertMessage({ message: "Item Added in Cart!" });
      if (string) {
        navigate("/cart");
      }
    }

    setCartCountCheck(!cartCountCheck);
  }
  useEffect(() => {
    // debugger;
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  const productSaveHandler = (e, res) => {
    const object = {
      user_id: userId,
      variant_id: res.product_variants[0].id,
      product_id: res.id,
      ...res,
    };
    setLoaderCheck(true);
    axiosPrivate
      .post("wishlist/create", object)
      .then((res) => {
        setLoaderCheck(false);
        if (res.data?.code == 201) {
          successToast("Product added to Wishlist");
        }
      })
      .catch((err) => {
        setLoaderCheck(false);
        errorToast(err?.response?.data?.message);
      });
  };

  return (
    <div className="container">
      <div>
        {categoryData
          ?.filter((res) => res.slug === `products/${slug}`)
          .map((res) => (
            <>
            <Helmet>
          <title>{res?.meta_title}</title>
          <meta name="description" content={res?.meta_description} />
          <meta name="keywords" content={res?.meta_keywords} />
          <meta name="tags" content={res?.tags?.map(tag => tag?.text).join(',')} />
          <meta property="og:title" content={res?.og_title} />
          <meta
            property="og:description"
            content={res?.og_description}
          />
          <meta property="og:image" content={res?.og_image} />
          <meta property="og:url" content={res?.og_image} />
          <meta name="twitter:title" content={res?.meta_title} />
          <meta
            name="twitter:description"
            content={res?.meta_description}
          />
          <meta name="twitter:image" content={res?.og_image} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
              <div className="text-center">
                <h2 className={styles.secondHeading}>{res.name}</h2>
              </div>
              <div className={styles.imgDiv}>
                <img
                  className={styles.categoryImg}
                  src={`${process.env.REACT_APP_COMMON_FILE_URL}categories/${
                    JSON.parse(res?.image)?.file
                  }`}
                  alt="category"
                  loading="lazy"
                />

                <div
                  className={styles.categoryContent}
                  dangerouslySetInnerHTML={{ __html:res?.description,}}
                />
              </div>
            </>
          ))}
      </div>
      <div className={styles.productListContainer}>
        {product.length > 0 && (
          <>
            {product
              .filter(
                (product) => product?.categories_slug === `products/${slug}`
              )
              .map((product, index) => (
                <>


                  {product?.product_variants?.length > 0 ? (
                    <div className={styles.productList}>
                      <div className={styles.productImg}>
                        <Link
                          to={`/product-detail/` + productslug(product.slug)}
                        >
                          <img
                            src={product?.product_images[0]?.image}
                            alt={product.name}
                            loading="lazy"
                          />
                        </Link>
                      </div>
                      <div className={`${styles.productDetail}`}>
                        <Link
                          to={`/product-detail/` + productslug(product.slug)}
                        >
                          <h1 className={styles.productName}>{product.name}</h1>
                        </Link>
                        <p>{product.forms}</p>
                        <div
                          style={{ marginTop: "20px", fontSize: "14px" }}
                          dangerouslySetInnerHTML={{
                            __html: product?.short_description,
                          }}
                        />
                        {/* <p>
                          <strong>Types:</strong> {product.types}
                        </p> */}

                        {/* <p className='pr-5 pr-sm-0'>
                          
                          <strong>Suitable:</strong>
                          {product?.short_description?.length > 364
                            ? product.short_description.slice(0, 364) + '...'
                            : product.short_description}
                        </p> */}

                        <div className={`${styles.desktopLinks}`}>
                          <div className={`${styles.desktopBtn}`}>
                            <Link
                              to={
                                `/product-detail/` + productslug(product.slug)
                              }
                            >
                              <button
                                className="btn btn-primary"
                                style={{ background: "#7fb401" }}
                                //onClick={() => addToCart(product, "buynow")}
                              >
                                Shop Now
                              </button>
                            </Link>
                            <a
                              href={`${process.env.REACT_APP_COMMON_FILE_URL}/products/${product?.product_pdf}`}
                            >
                              <button
                                className="btn btn-primary ml-2"
                                style={{ background: "#7fb401" }}
                              >
                                Know More
                              </button>
                            </a>
                          </div>
                          <div className={`${styles.desktopBtn}`}>
                            {product?.amazon_link !== null &&
                              product?.amazon_link !== "" &&
                              product?.amazon_link !== "null" && (
                                <Link
                                  to={product?.amazon_link}
                                  className={`${styles.desktopLogo}`}
                                >
                                  <img
                                    className={`${styles.desktopLogoImage1}`}
                                    src={amazonImg}
                                    alt="amazon-logo"
                                    loading="lazy"
                                  />
                                </Link>
                              )}

                            {product?.flipkart_link !== null &&
                              product?.flipkart_link !== "" &&
                              product?.flipkart_link !== "null" && (
                                <Link
                                  className={`${styles.desktopLogo}`}
                                  to={product?.flipkart_link}
                                >
                                  <img
                                    className={`${styles.desktopLogoImage2}`}
                                    src={flipkartImg}
                                    alt="flipkart-logo"
                                    loading="lazy"
                                  />
                                </Link>
                              )}
                          </div>
                        </div>
                      </div>
                      {/* <div className={styles.buttons}> */}
                      {/* {product.product_variants.map((item,index)=>{ */}

                      <>
                        {/* <div
                            className={`${styles.productVarient} productVarient`}
                          >
                            <div className="card flex justify-content-center">
                              <Dropdown
                                value={selectedVarient}
                                onChange={(e) => handleChange(product.slug, e)}
                                options={product.product_variants}
                                optionLabel="variant"
                                optionValue="id"
                                placeholder="Select Variants"
                                className="w-full md:w-14rem"
                              />
                            </div>
                          </div> */}
                        {/* <div className={styles.productPrice}>
                        <BsCurrencyRupee />
                        <p>{product?.product_variants[0].regular_price}</p>
                      </div> */}
                        {/* <div className={styles.productVarient}>
                            <button onClick={() => addToCart(product)}>
                              <TbGardenCart className={styles.cart} />
                            </button>
                            <button
                              onClick={(e) => productSaveHandler(e, product)}
                            >
                              <FaBookmark className={styles.save} />
                            </button>
                          </div> */}
                      </>

                      {/* })} */}
                      {/* </div> */}
                      <div className={styles.mobileBtn}>
                        <Link
                          to={`/product-detail/` + productslug(product.slug)}
                        >
                          <button
                            className="btn btn-primary ml-2 showMoreBtn"
                            style={{ background: "#7fb401" }}
                            //onClick={() => addToCart(product, "buynow")}
                          >
                            Shop Now
                          </button>
                        </Link>
                        <a
                          href={`${process.env.REACT_APP_COMMON_FILE_URL}/products/${product?.product_pdf}`}
                          target="_blank"
                        >
                          <button
                            className="btn btn-primary ml-2 knowMoreBtn"
                            style={{ background: "#7fb401" }}
                          >
                            Know More
                          </button>
                        </a>
                        {/* <div className={styles.productPriceMobile}>
                          <BsCurrencyRupee />
                          <p>{product.price}</p>
                        </div> */}

                        {product?.amazon_link !== null &&
                          product?.amazon_link !== "" &&
                          product?.amazon_link !== "null" && (
                            <Link
                              to={product?.amazon_link}
                              className={`${styles.desktopLogo}`}
                            >
                              <img
                                className={`${styles.desktopLogoImage1}`}
                                src={amazonMobileImg}
                                alt="amazon-logo"
                                loading="lazy"
                              />
                            </Link>
                          )}

                        {product?.flipkart_link !== null &&
                          product?.flipkart_link !== "" &&
                          product?.flipkart_link !== "null" && (
                            <Link
                              className={`${styles.desktopLogo}`}
                              to={product?.flipkart_link}
                            >
                              <img
                                className={`${styles.desktopLogoImage2}`}
                                src={flipkartMobileImg}
                                alt="flipkart-logo"
                                loading="lazy"
                              />
                            </Link>
                          )}
                      </div>
                    </div>
                  ) : (
                    <h6 className={styles.secondHeading}>No Products Found</h6>
                  )}
                </>
              ))}
          </>
        )}
      </div>
    </div>
  );
}

export default React.memo(KitchenTerraceProducts); // Memoize the KitchenTerraceProducts;
