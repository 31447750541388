import React, { useContext, useEffect, useState } from "react";
import styles from "./Cart.module.css";
import { MdDelete } from "react-icons/md";
import { IoTrashOutline } from "react-icons/io5";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { Link, useNavigate } from "react-router-dom";
import CartCountContext from "../../../context/CartCount/CartCountContext";
import { errorToast, successToast } from "../../backend/common/Toaster";
import LoaderContext from "../../../context/Loader/LoaderContext";
import { showErrorToast, showSuccessToast } from "../../../Toster";
import CheckoutOtp from "./checkout-otp";
import { Dialog } from "primereact/dialog";
import CheckoutContext from "../../../context/Checkout/CheckoutContext";
import SignIn from "../../auth/SignIn";


function Cart() {
  const navigate = useNavigate();
  const [product, setProcduct] = useState([]);
  const [cartitems, setCartItems] = useState([]);
  const [cart_updation, updateCartItems] = useState(0);
  const [subTotal, setsubTotal] = useState(0);
  const [Total, setTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [shipping, setShippingTotal] = useState(0);
  const { cartCountCheck, setCartCountCheck, setCartCount } =
    useContext(CartCountContext);
  const { setLoaderCheck } = useContext(LoaderContext);
  const [refresh, setRefresh] = useState(false);
  const { showCheckout, setShowCheckout } = useContext(CheckoutContext);
  let userLogin = JSON.parse(localStorage.getItem("userData"));

  let action = localStorage.getItem("action");

  //get cart items

  const getCartItems = () => {
    setCartCountCheck((prev) => !prev);
    if (userLogin) {
      setLoaderCheck(true);
      axiosPrivate
        .get("carts/get")
        .then((res) => {
          setCartItems(res?.data?.data);
          setLoaderCheck(false);
        })

        .catch((error) => {
          setLoaderCheck(false);
          // showErrorToast(error.response?.data?.message)
          if (error?.response?.data?.data?.length == 0) {
            setCartItems([]);
          }
        });
    } else {
      let cart_local_array = localStorage?.getItem("cart_items");
      if (cart_local_array) {
        let parsed_data = JSON?.parse(cart_local_array);
        setCartItems(parsed_data);
      } else {
        setCartItems([]);
      }
    }
    setCartCountCheck((prev) => !prev);
  };
  useEffect(() => {
    getCartItems();
  }, [refresh]);

  //delete cart
  async function deleteItem(res, index) {
    if (!userLogin) {
      // Update local cart items immutably
      const updatedCart = cartitems?.filter((_, i) => i !== index);

      if (updatedCart.length > 0) {
        localStorage.setItem("cart_items", JSON.stringify(updatedCart));
      } else {
        localStorage.removeItem("cart_items");
      }

      setRefresh((prev) => !prev);
      updateCartItems(cart_updation + 1);
      successToast("Cart Updated Successfully!");
    } else {
      try {
        setLoaderCheck(true);
        await axiosPrivate.delete(`carts/${res.id}`);
        getCartItems(); // Fetch updated cart items
        successToast("Cart Item deleted Successfully!");
      } catch (error) {
        console.error("Error deleting cart item:", error);
      } finally {
        setLoaderCheck(false);
        setRefresh((prev) => !prev);
      }
    }

    setCartCountCheck((prev) => !prev);
  }

  //get total
  useEffect(() => {
    let subTotal = 0;
    cartitems?.forEach((res) => {
      subTotal = subTotal + (res.rate ?? res?.regular_price) * res.quantity;
    });
    setsubTotal(subTotal);
    //setTotalAmount(totalSaving + orderTotal + taxes + shipping + subTotal);
  }, [cartitems]);

  //get total
  useEffect(() => {
    let Total = 0;
    cartitems?.forEach((res, index) => {
      Total = Total + (res.rate ?? res?.regular_price) * res.quantity;
      if (index == cartitems?.length - 1) {
        Total = Total + shipping;
      }
    });
    setTotal(Total);
    //setTotalAmount(totalSaving + orderTotal + taxes + shipping + subTotal);
  }, [cartitems]);

  function updateQuantity(res, string, index) {
    const updatedCartItems = [...cartitems];

    if (index !== -1) {
      if (string === "add") {
        updatedCartItems[index].quantity += 1;
      } else if (string === "remove" && updatedCartItems[index].quantity > 1) {
        updatedCartItems[index].quantity -= 1;
      }

      // Update local storage
      localStorage.setItem("cart_items", JSON.stringify(updatedCartItems));

      // Update cart items state
      setCartItems(updatedCartItems); // Update the state with the new array

      if (!userLogin) {
        updateCartItems(cart_updation + 1); // Make sure this function is updating the UI properly
      } else {
        const object = {
          product_id: res.product_id,
          type: string === "add" ? "plus" : "minus",
          quantity: string === "add" ? 1 : -1, // Adjust quantity value for remove
          variant_id: res?.variant_id,
        };
        setLoaderCheck(true);
        axiosPrivate
          .put("/carts/update/" + res.id, object)
          .then((res) => {
            setLoaderCheck(false);
            updateCartItems(cart_updation + 1); // Make sure this function is updating the UI properly
            setRefresh(true);
          })
          .catch((error) => {
            setLoaderCheck(false);
            // Handle error
          });
      }
    }
  }

  return (
    <>
      {!userLogin && (
        <Dialog
          header="Login here"
          visible={showCheckout}
          onHide={() => setShowCheckout(false)}
          style={{ padding: "0px", maxWidth: "90%" }}
        >
          <SignIn isCart={true} />
          {/* <CheckoutOtp /> */}
        </Dialog>
      )}

      <div className={styles.cartContainer}>
        <div className={styles.mainContainer}>
          <div
            className={styles.mainHeadingContainer}
            style={{ marginBottom: "30px" }}
          >
            <h2 className={styles.mainHeading}>
              Shopping Cart
              <img
                src="../assets/images/home/leaf-1-1 1.webp"
                alt="leaf"
                loading="lazy"
                className={styles.leaf}
              />
            </h2>
          </div>
          {/* <p id="mainContent" className={styles.mainContent}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
          turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec
          fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus
          elit sed risus. Maecenas eget condimentum velit, sit amet feugiat
          lectus. Class aptent taciti sociosqu ad litora torquent per conubia
          nostra, per inceptos himenaeos.
        </p> */}
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <th className={styles.productTable}>Product</th>
              <th className={styles.productTable2}>Price</th>
              <th className={styles.productTable2}>Quantity</th>
              <th style={{ minWidth: "8rem" }}>Total Price</th>
              <th className={styles.productTable2}>Remove</th>
            </thead>
            <tbody>
              {cartitems?.length > 0 ? (
                cartitems?.map((res, index) => (
                  <tr>
                    <td>
                      <div className="background_div">
                        {userLogin ? (
                          <div className="d-flex ">
                            <div style={{ marginLeft: "-1vw" }}>
                              <img
                                style={{ width: "5rem" }}
                                // src={
                                //   process.env.REACT_APP_COMMON_FILE_URL +
                                //   action === "login" ? '/product-variants/' +
                                //   res?.variant_iamge[0]?.image : res?.image
                                // }
                                src={
                                  action === "login"
                                    ? process.env.REACT_APP_COMMON_FILE_URL +
                                      "product-variants/" +
                                      res?.variant_iamge[0]?.image
                                    : process.env.REACT_APP_COMMON_FILE_URL +
                                      res?.image
                                }
                                alt="variant"
                                loading="lazy"
                              />
                            </div>
                            <div>
                              <h5>{res?.product_name || res?.name}</h5>
                              <span>Variant : {res?.variant}</span>
                            </div>
                          </div>
                        ) : (
                          <>
                            {
                              <div className="">
                                <img
                                  style={{ width: "5rem" }}
                                  src={`${
                                    process.env.REACT_APP_COMMON_FILE_URL +
                                    "product-variants/" +
                                    res.image
                                  }`}
                                  alt="product"
                                  loading="lazy"
                                />
                                {res?.product_name}
                              </div>
                            }
                          </>
                        )}
                      </div>
                    </td>
                    <td>
                      <div>
                        <p>₹{res?.regular_price ?? res?.rate}</p>
                      </div>
                    </td>
                    <td>
                      <div className={styles.buttonGroup}>
                        <button
                          onClick={() => updateQuantity(res, "remove", index)}
                        >
                          -
                        </button>
                        <span> {res.quantity}</span>
                        <button
                          onClick={() => updateQuantity(res, "add", index)}
                          style={{ padding: "inherit" }}
                        >
                          +
                        </button>
                      </div>
                    </td>
                    <td>
                      <div className="">
                        <p>
                          ₹{(res?.regular_price ?? res?.rate) * res?.quantity}
                        </p>
                      </div>
                    </td>
                    <td>
                      <IoTrashOutline
                        size={20}
                        color="red"
                        cursor={"pointer"}
                        onClick={() => deleteItem(res, index)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <div className="m-5">
                  <p className={styles.Carttext}>Your Cart is Empty!!</p>
                  <Link to={"/urban-gardens"}>
                    <button
                      className={`${styles.cartbutton} ${styles.Carttext}`}
                    >
                      Shop Now
                    </button>
                  </Link>
                </div>
              )}
            </tbody>
          </table>
        </div>
        <hr />
        <div className="cart_card">
          <div className="row">
            <div className="col">
              <h4>Cart Total</h4>
              <p>Sub Total:</p>
              <p>Shipping:</p>
              <br />
              <p style={{ fontWeight: "bold" }}>Total:</p>
            </div>
            <div className="col text-right">
              <h4> </h4>
              <br />
              <p>₹ {subTotal}</p>
              <p>Free</p>
              <br />
              <p style={{ fontWeight: "bold" }}>₹ {Total}</p>
            </div>
          </div>
          <div className={styles.proceedButton}>
            <button
              className={styles.cartbutton}
              onClick={() => {
                setShowCheckout(true);
                if (userLogin) {
                  navigate("/checkout");
                }
              }}
            >
              Proceed to Checkout
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(Cart); // Memoize the Cart;
