import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import AlertContext from "../../../../context/Alert/AlertContext";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import Changepassword from "./Changepassword";
import classes from "./UserProfile.module.css";
import { errorToast, successToast } from "../../common/Toaster";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// validation
const validationRules = {
  name: Yup.string()
    .max(120, "Name must not exceed 120 characters")
    .required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),

  mobile: Yup.string()
    .required("Primary Mobile is required")
    .matches(phoneRegExp, "Mobile number is not valid")
    .min(10, "Mobile number must be of 10 digits")
    .max(10, "Mobile number must be of 10 digits"),
};

const Userprofile = () => {
  let { id } = useParams();
  const { setAlertMessage } = useContext(AlertContext);
  const navigate = useNavigate();
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const [getData, setGetData] = useState({});

  const OpenpasswordModel = () => {
    setIsPasswordChange(true);
  };

  const closeModelPassword = () => {
    setIsPasswordChange(false);
  };

  // user get by id

  useEffect(() => {
    axiosPrivate
      .get(`users/get`)
      .then((response) => {
        if (response.data.code === 200) {
          successToast("Profile Fetched Successfully");
          setGetData({ ...response.data.data, password: "" });
        }
      })
      .catch((error) => {
        if (error.response) {
          //response status is an error code
          errorToast("Internal server error");
        } else if (error.request) {
          //response not received though the request was sent
          errorToast("Internal server error");
        } else {
          //an error occurred when setting up the request
          errorToast("Internal server error");
        }
      });
  }, []);

  useEffect(() => {
    if (getData != "") {
      formik.setValues({ ...getData });
    }
  }, [getData]);

  // formik user Update

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      additional_mobile: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      var values = {
        name: values.name,
        email: values.email,
        primary_mobile: values.mobile,
      };

      axiosPrivate
        .put(`/users/${id}`, values)
        .then((response) => {
          setAlertMessage({ message: "updated successfully" });
          let user = JSON.parse(localStorage.getItem("userDetails"));
          user.name = values.name;
          user.email = values.email;
          user.mobile = values.mobile;
          localStorage.setItem("userDetails", JSON.stringify(user));
          console.log(user);
          navigate("/admin/dashboard");
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 422) {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                setFieldError(
                  error.response.data.errors[i].param,
                  error.response.data.errors[i].msg
                );
              }
            }
          }
        });
    },
  });

  return (
    <>
      <div className="section-body pt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div
              className="tab-pane fade show active"
              id="user-add"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-header">
                  <strong style={{ fontWeight: "700", fontSize: "18px" }}>
                    User Profile
                  </strong>
                </div>
                <form onSubmit={formik.handleSubmit} id="userprofile">
                  <div className="card-body">
                    <div className="row clearfix">
                      {/* Name */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Name:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="User Name "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Email */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Email:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            className="form-control"
                            placeholder="Email Name "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Primary Mpbile Number */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Primary Mobile Number:
                          <span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="mobile"
                            name="mobile"
                            type="text"
                            className="form-control"
                            placeholder="Primary Mobile Number "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.mobile}
                          />
                          {formik.touched.mobile && formik.errors.mobile ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.mobile}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Secondary Mobile Number */}
                      {/* <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Secondary Mobile Number:
                          <span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="additional_mobile"
                            name="additional_mobile"
                            type="text"
                            className="form-control"
                            placeholder="Secondary Mobile Number "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.additional_mobile}
                          />
                          {formik.touched.additional_mobile &&
                          formik.errors.additional_mobile ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.additional_mobile}
                            </div>
                          ) : null}
                        </div>
                      </div> */}
                      <div className="col-lg-6 col-md-6 col-sm-12"></div>
                      {/* chang Password link */}
                      {/* <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <a
                            href="#"
                            onClick={() => OpenpasswordModel()}
                            style={{
                              fontSize: "15px",
                              textDecoration: "underline",
                            }}
                          >
                            Change Password
                          </a>
                        </div>
                      </div> */}

                      {/* button */}
                      {isPasswordChange ? (
                        ""
                      ) : (
                        <div className="col-12 submit-btn text-right">
                          <div className="table-responsive">
                            <table className="table table-striped"></table>
                          </div>
                          <button
                            type="button"
                            id="button_1"
                            className="btn btn-secondary mx-1"
                            data-dismiss="modal"
                            onClick={() => navigate("/admin")}
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            id="button_2"
                            className="btn btn-primary "
                          >
                            Submit
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </form>

                <div className="card-body" style={{ padding: "0 20px 20px" }}>
                  {isPasswordChange ? (
                    <Changepassword changePasswordClose={closeModelPassword} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Userprofile;
