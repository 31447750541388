import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import DataTable2 from '../../../DataTable2';
import TopNavBar from '../../layouts/TopNavBar';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import { errorToast, successToast } from '../../common/Toaster';
import LoaderContext from '../../../../context/Loader/LoaderContext';
import AuthContext from '../../../../context/Auth/AuthContext';

const FrequentProducts = () => {
    const [frequentlyProducts, setFrequentlyProducts] = useState([]);
    const { setLoaderCheck } = useContext(LoaderContext);
    const { authPermissions } = useContext(AuthContext);
    const headers = [
        // { name: 'S NO', field: 'sr_no', sortable: true, classKey: '' },
        { name: 'NAME', field: 'name', sortable: true, classKey: '' },
        { name: 'FREQUENCY', field: 'frequency', sortable: true, classKey: '' },
        { name: 'ACTION', field: 'action', classKey: '' },
    ];
    const searchItems = ['name'];
    
    

    //Get Product List
    useEffect(() => {
        const options = {
            headers: { 'content-type': 'application/json' },
        };
        setLoaderCheck(true);
        axiosPrivate
            .get('fbt', options)
            .then((frequent) => {
                setLoaderCheck(false);
                const productsData = frequent.data.data.map((value, index) => {
                    let buttons = [];
                    if (authPermissions.includes('Product FBT-update'))
                        buttons.push(
                            <Link
                                key="editButton##1"
                                type="button"
                                to={`/admin/frequently-products/edit/${value.product_id}`}
                                className="btn btn-icon"
                                title="Edit"
                            >
                                <i className="fa fa-edit"></i>
                            </Link>
                        );
                    if (authPermissions.includes('Product FBT-delete'))
                        buttons.push(
                            <button
                                key="deleteButton##1"
                                type="button"
                                data-id={value.product_id}
                                onClick={() => frequentlyProductDeleteHandler(value.product_id)}
                                className="btn btn-icon js-sweetalert"
                                title="Delete"
                            >
                                <i className="fa fa-trash-o text-danger"></i>
                            </button>
                        );
                    value['action'] = buttons.length > 0 ? buttons : '-';
                    value['sr_no'] = index + 1;
                    return value;
                });

               
                if (authPermissions.includes('Product FBT-read')) {
                    setFrequentlyProducts(productsData);
                } else {
                    setFrequentlyProducts([]);
                }
            })
            .catch((error) => {
                setLoaderCheck(false);
                errorToast(error?.response?.data?.message);
            });
    }, []);

    //Function to delete a product
    const frequentlyProductDeleteHandler = async (id) => {
        setLoaderCheck(true);
        axiosPrivate
            .delete(`fbt/${id}`)
            .then(async (response) => {
                setLoaderCheck(false);
                if (response.data.code === 200) {
                    setFrequentlyProducts([]);
                    successToast('Frequently Product deleted successfully');
                    await refreshTable();
                }
            })
            .catch((error) => {
                setLoaderCheck(false);
                errorToast(error?.response?.data?.message);
            });
    };

    //Function to refresh data after every api call
    const refreshTable = () => {
        return new Promise((resolve, reject) => {
            const options = {
                headers: { 'content-type': 'application/json' },
            };
            setLoaderCheck(true);
            axiosPrivate
                .get('fbt', options)
                .then((frequently) => {
                    setLoaderCheck(false);
                    const productsData = frequently.data.data.map((value, key) => {
                        let buttons = [];
                        if (authPermissions.includes('Product FBT-update'))
                            buttons.push(
                                <Link
                                    key="editButton##1"
                                    type="button"
                                    to={`/admin/frequently-products/edit/${value.product_id}`}
                                    className="btn btn-icon"
                                    title="Edit"
                                >
                                    <i className="fa fa-edit"></i>
                                </Link>
                            );
                        if (authPermissions.includes('Product FBT-delete'))
                            buttons.push(
                                <button
                                    key="deleteButton##1"
                                    type="button"
                                    data-id={value.product_id}
                                    onClick={() => frequentlyProductDeleteHandler(value.product_id)}
                                    className="btn btn-icon js-sweetalert"
                                    title="Delete"
                                >
                                    <i className="fa fa-trash-o text-danger"></i>
                                </button>
                            );
                        value['action'] = buttons.length > 0 ? buttons : '-';
                        return value;
                    });
                    if (authPermissions.includes('Product FBT-read')) {
                        setFrequentlyProducts(productsData);
                    } else {
                        setFrequentlyProducts([]);
                    }
                    resolve(true);
                })
                .catch((error) => {
                    setLoaderCheck(false);
                    reject(error);
                    errorToast(error?.response?.data?.message);
                });
        });
    };
    const addLink = authPermissions.includes('Product FBT-create') === true ? '/admin/frequently-products/add' : '';

    return (
        <>
            <TopNavBar links={{ list: '/admin/frequently-products', add: addLink }} />
            <div className="section-body">
                <div className="container-fluid">
                    <div className="tab-content">
                        <div
                            className="tab-pane fade show active"
                            id="product-list"
                            role="tabpanel"
                        >
                            <div className="card">
                                <div className="card-header">
                                    {/* <h3 className="card-title">PRODUCT LIST</h3> */}
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        {
                                            <DataTable2
                                                lists={frequentlyProducts}
                                                headers={headers}
                                                searchItems={searchItems}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FrequentProducts;
