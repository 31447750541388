import React from "react";
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import styles from "./ProductReviewSection.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StarRating from "../StarRating/StarRating";

const reviews = [
  {
    name: "Sagar Barot",
    rating: 4,
    title: "Awesome organic fertilizer",
    review:
      "They have these osum 500gms jars of organic fertilizer in a pellet form. I don’t have to put anything extra for my home garden. Getting my own lovely tomatoes.",
  },
  {
    name: "Sophia R.",
    rating: 5,
    title: "Highly recommended!",
    review:
      "Minfert products have significantly improved the health of my crops this season. The soil quality has drastically improved, and the yields are higher than last year. Their fertilizers have shown quick results. I will definitely continue using them for my farming.",
  },
  {
    name: "John Doe",
    rating: 4,
    title: "Best Choice",
    review:
      "The soil quality has drastically improved, and the yields are higher than last year. Their fertilizers have shown quick results. I will definitely continue using them for my farming.",
  },
];

const ProductReviewSection = ({ product }) => {
  const productCount = product?.product_reviews?.length;
  // Slick Slider settings
  const settings = {
    dots: false, // Display navigation dots
    infinite: productCount > 0 ? (productCount > 3 ? true : false) : true, // Infinite scrolling
    speed: 500, // Transition speed
    slidesToShow: 3, // Show 3 slides at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true,
    autoplaySpeed: 5000, // Set the interval for autoplay
    nextArrow: <FaChevronRight size={30} color="#666666" />,
    prevArrow: <FaChevronLeft size={30} color="#666666" />,
    responsive: [
      {
        breakpoint: 1025, // Tablets
        settings: {
          slidesToShow: 2,
          infinite: productCount > 0 ? (productCount > 2 ? true : false) : true, // Infinite scrolling
        },
      },
      {
        breakpoint: 576, // Mobile devices
        settings: {
          slidesToShow: 1, // Show 1 slide at a time
          infinite: productCount > 0 ? (productCount > 1 ? true : false) : true, // Infinite scrolling
        },
      },
    ],
  };
  
  return (
    <div id="productReviews" className={styles.carouselContainer}>
      <div className="container">
      <h2 className={styles.title}>Product Reviews</h2>
      <p className={styles.subtitle}>
        {/* <span className={styles.rating}>{product?.star_rating}</span>{" "} */}
        <span>
          <StarRating
            value={product?.star_rating}
          />
        </span>
        <span className={styles.reviews}>{product?.number_of_rating}</span>
      </p>
      <div id="reviewSlider">
        <Slider {...settings}>
          {(product?.product_reviews?.length > 0
            ? product?.product_reviews
            : reviews
          ).map((review, index) => (
            <div key={index} className={styles.reviewCard}>
              <div>
                <div className={styles.reviewHeader}>
                  <div>
                    <p className={styles.name}>{review.name}</p>
                    <div className={styles.ratingContainer}>
                      <StarRating
                        value={review.rating}
                      />
                    </div>
                  </div>
                  <img
                    src="/assets/images/home/QuotationImg.svg"
                    alt="ExclusiveProduct"
                    loading="lazy"
                    //   className={styles.exclusiveProductImage}
                  />
                </div>
                <h3 className={styles.reviewTitle}>{review.review_title}</h3>
                <p className={styles.reviewText}>{review.review}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      </div>
    </div>
  );
};

export default ProductReviewSection;
