import React, { useState } from "react";
import styles from "./Footer.module.css";
import { HiLocationMarker } from "react-icons/hi";
import { IoMdCall } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import { ImFacebook } from "react-icons/im";
import { AiFillInstagram, AiOutlineArrowRight } from "react-icons/ai";
import { FaTwitter } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io";
import { TiSocialYoutube } from "react-icons/ti";
import { errorToast, successToast } from "../../backend/common/Toaster";
import axiosPrivate from "../../../hooks/axiosPrivate";
import MinfertLogo from "../../../assets/images/logo.webp"
import styles1 from './FooterBar.module.css'

function Footer() {
  const [mail, setMail] = useState({ email: ""});
  const [preventColorChange, setPreventColorChange] = useState(true);

  const handleSubscribe = (event) => {
    if(event) {
      event.preventDefault();
    }

    if(!mail.email) {
      errorToast("Please Enter Email");
      return;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(mail.email)) {
      errorToast("Please enter a valid email address");
      return;
    }
    
  const object={
    ...mail,
  form_type : "subscription" ,
  }
    axiosPrivate
      .post("/contact-us",object)
      .then((res) => {
        successToast("Email Subscribed");
        setMail({email:''})
      })
      .catch((error) => {
        if (typeof error.response.data.errors == "array") {
          errorToast(error.response.data.errors[0].msg);
        } else {
          errorToast(error.response.data.errors.msg);
        }
      });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // You can use 'auto' for instant scrolling
    });
  };

  const handleIconClick = () => {
    setPreventColorChange(true);
  };

  return (
    <div className={styles.footerMainContainer}>
      <div className={`${styles.footer} container px-3`}>
      {/* <img
        src="./assets/images/home/review_bg_1 1.webp"
        alt="Background"
        loading="lazy"
        className={styles.backgoundImg}
      /> */}

      {/* Logo */}
      <div className={styles.logoContainer}>
        <div className={styles.logo}>
          <img
            src={MinfertLogo}
            alt="Minfert"
            loading="lazy"
          />
        </div>
        <p className={styles.logoContent}>
          The Plant Food Manufacturer MINFERT comes from a state of the art
          research centre and technical expertise in the Fields of farming and
          offers a range of mineral-based plant food, insecticides, and soil
          conditioners to gain better yield and quality crops.
        </p>
      </div>

      {/* contact */}
      <div className={styles.contactContainer}>
        <h4 className={`${styles.MainHeading} ${styles.marginLeft}`}>
          Contact
        </h4>
        <div className={styles.location}>
          <div className={styles.icons}>
            <Link to="/contact-us/" onClick={handleIconClick}>
              <HiLocationMarker
                className={
                  preventColorChange ? styles["prevent-color-change"] : ""
                }
              />
            </Link>
          </div>
          <div>
            <h6 className={styles.subHeading}>20MCC Pvt. Ltd.</h6>
            <p className={styles.address}>
              Plot No.: 256, GIDC Waghodia, 391760 Dist. Vadodara, Gujarat,
              India
            </p>
          </div>
        </div>
        <div className={styles.location}>
          <div className={styles.icons}>
            <a href="tel:1800-313-1171" onClick={handleIconClick}>
              <IoMdCall
                className={
                  preventColorChange ? styles["prevent-color-change"] : ""
                }
              />
            </a>
          </div>
          <p className={styles.address}>
            Order on:
            <a href="tel:7574011099">
              <span> +91 7574011099</span>
            </a>
          </p>
        </div>
        <a href="mailto:sales@minfert.in" onClick={handleIconClick}>
          <div className={styles.location}>
            <div className={styles.icons}>
              <MdEmail
                className={
                  preventColorChange ? styles["prevent-color-change"] : ""
                }
              />{" "}
            </div>{" "}
            <p className={styles.address}>sales@minfert.in</p>
          </div>
        </a>
      </div>

      {/* follow us */}
      <div className={styles.socialContainer}>
        <h4 className={styles.MainHeading}>Follow Us</h4>
        <div className={styles.social}>
          <Link to="https://www.facebook.com/minfert/" target="_blank">
            <div className={styles.socialLink}>
              <ImFacebook />
            </div>
          </Link>

          <Link to="https://www.instagram.com/minfert.in/" target="_blank">
            <div className={styles.socialLink}>
              <AiFillInstagram />
            </div>
          </Link>

          <Link to="https://twitter.com/Minfert_Organic" target="_blank">
            <div className={styles.socialLink}>
              <FaTwitter />
            </div>
          </Link>

          <Link to="https://in.linkedin.com/company/minfert" target="_blank">
            <div className={styles.socialLink}>
              <IoLogoLinkedin />
            </div>
          </Link>

          <Link to="https://www.youtube.com/@minfert" target="_blank">
            <div className={styles.socialLink}>
              <TiSocialYoutube />
            </div>
          </Link>
        </div>
        <div>
          <img src="/assets/images/home/amazon.svg" alt="buy" 
          width={100}
          height={100}/>
        </div>
      </div>

      {/* Subscribe */}
      <div className={styles.subscribeContainer}>
        <h4 className={styles.MainHeading}>Subscribe</h4>
        <p className={styles.subscribeContent}>
          Enter your email and we’ll send you latest information and plans.
        </p>
        <div className={styles.searchContainer}>
          <input
            type="text"
            name="search"
            value={mail.email}
            onChange={(e) => {
              setMail({ email: e.target.value });
              
            }}
            onKeyPress={(e)=> {var code = e.keyCode ? e.keyCode : e.which;
              if (code == 13) {
                {
                  handleSubscribe();
                }
              }
            }}
          
            className={styles.search}
            placeholder="YOUR EMAIL ID"
          />
          <AiOutlineArrowRight
            className={styles.subscribe}
            onClick={() => handleSubscribe()}
            
          />
        </div>
      </div>
    </div>
    </div>
  );
}

export default React.memo(Footer); // Memoize the Footer;
