import React from "react";
import "./DistributorForm.css";
import { useForm } from "react-hook-form";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { showErrorToast } from "../../../Toster";
import LoaderContext from "../../../context/Loader/LoaderContext";
import { useContext } from "react";
import { successToast, errorToast } from "../../backend/common/Toaster";
import { useFormik } from "formik";
import * as Yup from "yup";
const DistributorForm = () => {
  const { setLoaderCheck } = useContext(LoaderContext);

  

  const formSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    mobile: Yup.string().test('starts-not-with-0-to-5', 'Please enter a valid phone number', function(value) {
      // Check if the mobile number does not start from 0 to 5
      if (!value) return true; // If no value, let Yup.string().required handle it
      return !/^[0-5]/.test(value);
    }).required("Phone number is required"),
    email: Yup.string()
      .matches(
        /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid Email!"
      )
      .required("Email is required"),
    city: Yup.string().required("Village/City is required"),
    taluka: Yup.string().required("Taluka/Tehsil is required"),
    district: Yup.string().required("District is required"),
    state: Yup.string().required("State is required"),
    // message: Yup.string().required("Message is required"),
    sell_platform: Yup.string().required("Sell_Platform is required"),
  });

  const formValues = {
    name: "",
    mobile: "",
    email: "",
    city: "",
    taluka: "",
    district: "",
    state: "",
    message: "",
    sell_platform: "",
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues: formValues,
    validationSchema: formSchema,
    onSubmit: (data, { resetForm }) => {
      data.form_type = "distributor";
      setLoaderCheck(true);
      axiosPrivate
        .post("/contact-us", data)
        .then((res) => {
          setLoaderCheck(false);

          resetForm();
          successToast("Form Submitted Successfully!");
        })
        .catch((error) => {
          setLoaderCheck(false);
          successToast("Something Went Wrong!");
          if (typeof error.response.data.errors == "array") {
            successToast(error.response.data.errors[0].msg);
          } else {
            successToast(error.response.data.errors.msg);
          }
        });
    },
  });

  

  const numberFilter = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    setFieldValue(event.target.name, numericValue);
  };

  const nameFilter = (event) => {
    const inputValue = event.target.value;
    if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
      setFieldValue(event.target.name, inputValue);
    }
  };

  return (
    <div className=" container1 container distributorContainer">
      <form className="col-sm-12 form" onSubmit={handleSubmit}>
        <h3>Distributor Form</h3>
        <p className="mb-5">
          You can join our network of dynamic partners and unlock new
          opportunities for growth and success!
        </p>

        <div className="row mb-3">
          <div className="col-sm">
            <label htmlFor="name">Name *</label>
            <input
              type="text"
              className="form-control form-control-lg"
              name="name"
              id="name"
              onChange={(event) => {
                nameFilter(event);
              }}
              onBlur={handleBlur}
              value={values.name}
            />
            {touched.name && errors.name ? (
              <div style={{ color: "red" }}>{errors.name}</div>
            ) : null}
          </div>

          <div className="col-sm">
            <label htmlFor="mobile">Phone Number*</label>

            <input
              type="text"
              className="form-control form-control-lg"
              name="mobile"
              id="mobile"
              onChange={(event) => {
                numberFilter(event);
              }}
              onBlur={handleBlur}
              value={values.mobile}
              maxLength={10}
            />
            {touched.mobile && errors.mobile ? (
              <div style={{ color: "red" }}>{errors.mobile}</div>
            ) : null}
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-sm">
            <label htmlFor="email">Email Address*</label>

            <input
              type="email"
              className="form-control form-control-lg"
              name="email"
              id="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            {touched.email && errors.email ? (
              <div style={{ color: "red" }}>{errors.email}</div>
            ) : null}
          </div>
          <div className="col-sm">
            <label htmlFor="city">Village/City*</label>

            <input
              type="text"
              className="form-control form-control-lg"
              name="city"
              id="city"
              onChange={(event) => {
                nameFilter(event);
              }}
              onBlur={handleBlur}
              value={values.city}
            />
            {touched.city && errors.city ? (
              <div style={{ color: "red" }}>{errors.city}</div>
            ) : null}
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-sm">
            <label htmlFor="taluka">Taluka/Tehsil*</label>

            <input
              type="text"
              className="form-control form-control-lg"
              name="taluka"
              id="taluka"
              onChange={(event) => {
                nameFilter(event);
              }}
              onBlur={handleBlur}
              value={values.taluka}
            />
            {touched.taluka && errors.taluka ? (
              <div style={{ color: "red" }}>{errors.taluka}</div>
            ) : null}
          </div>
          <div className="col-sm">
            <label htmlFor="district">District*</label>
            <input
              type="text"
              className="form-control form-control-lg"
              name="district"
              id="district"
              onChange={(event) => {
                nameFilter(event);
              }}
              onBlur={handleBlur}
              value={values.district}
            />
            {touched.district && errors.district ? (
              <div style={{ color: "red" }}>{errors.district}</div>
            ) : null}
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-sm">
            <label htmlFor="state">State*</label>

            <input
              type="text"
              className="form-control form-control-lg"
              name="state"
              id="state"
              onChange={(event) => {
                nameFilter(event);
              }}
              onBlur={handleBlur}
              value={values.state}
            />
            {touched.state && errors.state ? (
              <div style={{ color: "red" }}>{errors.state}</div>
            ) : null}
          </div>
          <div className="col">
            <label className="radio-label" htmlFor="message">
              Subject/Message
            </label>

            <textarea
              className="form-control form-control-lg"
              name="message"
              id="message"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.message}
            />
            
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12 col-md-6 pb-2">
            <div className="d-flex align-items-start flex-column">
              <label className="radio-label">
                How will you sell Minfert Products*
              </label>
              <div className="d-flex mt-1">
                <div className="form-check">
                  
                  <input
                    type="radio"
                    className="form-check-input"
                    id="online"
                    name="sell_platform"
                    value="online"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <label className="form-check-label" htmlFor="online">
                    On-line
                  </label>
                </div>
                <div className="form-check mx-1">

                  <input
                    type="radio"
                    className="form-check-input"
                    
                    name="sell_platform"
                    id="inStore"
                    value="inStore"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label className="form-check-label" htmlFor="inStore">
                    In-Store
                  </label>
                </div>
                <div className="form-check">
                  
                  <input
                    type="radio"
                    className="form-check-input"
                    
                    name="sell_platform"
                    id="other"
                    value="other"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  
                  <label className="form-check-label" htmlFor="other">
                    Other
                  </label>
                </div>
              </div>
              {touched.sell_platform && errors.sell_platform ? (
                    <div style={{ color: "red" }}>{errors.sell_platform}</div>
                  ) : null}
            </div>
          </div>
          <div className="col">
            <button type="submit" className="btn btn-primary btn-lg submitBtn">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default React.memo(DistributorForm); // Memoize the DistributorForm;
