import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for type checking
import styles from './BannerImage.module.css';
import { Link } from 'react-router-dom';
import { Carousel } from 'primereact/carousel';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

function BannerImage({ bannerValues }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (bannerValues?.bannerImage) {
      setItems([...bannerValues.bannerImage]); // Trigger re-render when new data arrives
    }
  }, [bannerValues]); // Re-run when bannerValues change

  const itemTemplate = (item) => {
    return (
      <div>
        <img
          src={item.Image}
          alt='Banner'
          width={100}
          height={100}
        />
      </div>
    );
  };

  return (
    <div className={styles.bannerImage}>
      <div className='position-relative'>
        {/* Use Carousel component for the carousel */}
        <Carousel
          value={items}
          itemTemplate={itemTemplate}
          numVisible={1}
          numScroll={1}
          circular
          autoplayInterval={5000}
          autoplay={true}
          
          className='custom-carousel'
        ></Carousel>
      </div>
      <div className={`container ${styles.bannerContent}`}>
        <h1 className={styles.mainHeading}>{bannerValues?.mainHeading}</h1>

        <div className={styles.imageContainer}>
          {bannerValues !== undefined &&
            bannerValues?.boxes?.map((box, index) => {
              return box?.Url ? (
                <Link to={box.Url} key={index}>
                  {' '}
                  {/* Added a unique key */}
                  <div
                    id={styles[box.backgroundColor]}
                    className={styles.fertilizer}
                  >
                    <div className={styles.boxImg}>
                      <img src={box?.image} alt={box?.heading} />
                    </div>
                    <h3 className={styles.boxHeading}>{box.heading}</h3>
                  </div>
                </Link>
              ) : (
                <Link to={'/'} key={index}>
                  {' '}
                  {/* Added a unique key */}
                  <div
                    id={styles[box.backgroundColor]}
                    className={styles.fertilizer}
                  >
                    <div className={styles.boxImg}>
                      <img src={box?.image} alt={box?.heading} loading='lazy' />
                    </div>
                    <h3 className={styles.boxHeading}>{box.heading}</h3>
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default React.memo(BannerImage); // Memoize the BannerImage;
