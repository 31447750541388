import React, { useState, useEffect } from "react";
import styles from "./ProductListing.module.css";
import { Link, useParams } from "react-router-dom";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import LoaderContext from "../../../context/Loader/LoaderContext";
import amazonImg from "../../../assets/images/amazon_new.webp";
import flipkartImg from "../../../assets/images/flipkart.png";
import amazonMobileImg from "../../../assets/images/AmazonMobilePNG.png";
import flipkartMobileImg from "../../../assets/images/flipkartMobile.png";
import StarRating from "../StarRating/StarRating";
function ProductDinamicPage({ initialValues }) {
  const { setLoaderCheck } = useContext(LoaderContext);
  const [isUrbanGarden, setIsUrbanGarden] = useState(window);

  function productslug(slug) {
    return slug
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "");
  }

  return (
    <div>
      {window.location.pathname?.includes("urban-gardens") ? (
        <div className={styles.urbanNewMainContainer}>
          <div className={styles.urbanHeadingMainContainer}>
            <img
              src="/assets/images/home/leafImage.png"
              alt="leaf"
              loading="lazy"
              className={styles.treeLeafImage}
            />
            <h1 className={styles.urbanNewHeading}>Urban Gardens</h1>
          </div>
          <div className={styles.urbanFirstSection}>
            <img
              src={initialValues?.mainImage}
              alt="urbanBannerImage"
              loading="lazy"
              className={styles.urbanBannerImage}
            />
            <div className={styles.overlapContainer}></div>
            <div className={styles.urbanBannerContents}>
              <h2 className={styles.urbanBannerHeading1}>Organic Solutions</h2>
              <span className={styles.urbanBannerHeading2}>
                for Effortless Urban Gardening
              </span>
            </div>
          </div>
          <div>
            <div style={{ position: "relative" }}>
              <img
                src="/assets/images/home/ellipse.png"
                alt="ellipse"
                loading="lazy"
                className={styles.secondSectionEllipse}
              />
            </div>

            <div className={`${styles.secondSectionCards} container`}>
              <div className={styles.whyChooseSection}>
                <div className={styles.whyChooseSubSection}>
                  <h4 className={styles.whyChooseSubSectionLeftContent}>
                    Why Choose Minfert?
                  </h4>
                  <div className={styles.whyChooseSubSectionRightContent}>
                    <div className={styles.rightLogoContentAlign}>
                      <img
                        src="/assets/images/home/OrganicLogo.png"
                        alt="ellipse"
                        loading="lazy"
                        className={styles.logoImg}
                      />
                      <div className={styles.logoSectionContent}>
                        100% Certified
                        <br />
                        Organic Product
                      </div>
                    </div>
                    <div className={styles.rightLogoContentAlign}>
                      <img
                        src="/assets/images/home/LowerCostLogo.png"
                        alt="ellipse"
                        loading="lazy"
                        className={styles.logoImg}
                      />
                      <div className={styles.logoSectionContent}>
                        Get More Produce
                        <br />
                        at Lower Cost
                      </div>
                    </div>
                    <div className={styles.rightLogoContentAlign}>
                      <img
                        src="/assets/images/home/SoilSofterLogo.png"
                        alt="ellipse"
                        loading="lazy"
                        className={styles.logoImg}
                      />
                      <div className={styles.logoSectionContent}>
                        Making Soil
                        <br />
                        Softer
                      </div>
                    </div>
                    <div className={styles.rightLogoContentAlign}>
                      <img
                        src="/assets/images/home/LabTestedLogo.png"
                        alt="ellipse"
                        loading="lazy"
                        className={styles.logoImg}
                      />
                      <div className={styles.logoSectionContent}>
                        Lab Tested and
                        <br />
                        Certified
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.secondSectionDescription}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: initialValues?.categorySection[0]?.content,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className={styles.exclusiveProductsSection}>
            <h6 className={styles.exclusiveProductHeading}>
              Exclusive Products
            </h6>
            <div className={`container ${styles.exclusiveProductsWrapper}`}>
              {initialValues?.ProductSection.map((product, index) => (
                <div className={styles.exclusiveProductCard}>
                  <img
                    src={product?.productImage}
                    alt="ExclusiveProduct"
                    loading="lazy"
                    className={styles.exclusiveProductImage}
                  />
                  <div className={styles.productDetailsMainContent}>
                    <div className={styles.productDetailSubContent}>
                      <div className={styles.productDetailsContent}>
                        <div className={styles.productName}>
                          {product?.name}
                        </div>
                        <div className="pt-1">
                          <StarRating size={20} value={product?.rating_star} />
                        </div>
                      </div>
                      <div className={styles.productCategory}>
                        {product?.category_name}
                      </div>
                    </div>
                    <div className={styles.buttonsContainer}> 
                      {/* <a
                        href={product?.product_pdf}
                        className={styles.cardInactiveButton}
                        target="_blank"
                      >
                        Know More
                      </a> */}
                      <Link
                        to={`/product-detail/` + productslug(product.slug)}
                        className={styles.cardActiveButton}
                      >
                        <button className="buttonDefaultStyle">Shop Now</button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div>
            <div className="text-center">
              <h2 className={styles.secondHeading}>
                {initialValues?.categorySection[0]?.heading}
              </h2>
            </div>
            <div className={styles.imgDiv}>
              <img
                className={styles.categoryImg}
                src={initialValues?.mainImage}
              />

              <div className={styles.categoryContent}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: initialValues?.categorySection[0]?.content,
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.productListContainer}>
            {initialValues?.ProductSection.length > 0 ? (
              <>
                {initialValues?.ProductSection.map((product, index) => (
                  <>
                    <div className={styles.productList}>
                      <div className={styles.productImg}>
                        <Link
                          to={`/product-detail/` + productslug(product?.slug)}
                        >
                          <img
                            src={product?.productImage}
                            alt={product.name}
                            loading="lazy"
                          />
                        </Link>
                      </div>
                      <div className={`${styles.productDetail}`}>
                        <Link
                          to={`/product-detail/` + productslug(product.slug)}
                        >
                          <h1 className={styles.productName}>{product.name}</h1>
                        </Link>
                        <Link to={`/${product.category_slug}`}>
                          <h4 style={{ fontSize: "1.1rem" }}>
                            {product?.category_name}
                          </h4>
                        </Link>
                        <p>{product.forms}</p>
                        <div
                          style={{ marginTop: "20px", fontSize: "14px" }}
                          dangerouslySetInnerHTML={{
                            __html: product?.short_description,
                          }}
                        />
                        <div className={`${styles.desktopLinks}`}>
                          <div className={`${styles.desktopBtn}`}>
                            <Link
                              to={
                                `/product-detail/` + productslug(product.slug)
                              }
                            >
                              <button
                                className="btn btn-primary"
                                style={{ background: "#7fb401" }}
                              >
                                Shop Now
                              </button>
                            </Link>
                            <a href={product?.product_pdf}>
                              <button
                                className="btn btn-primary ml-2"
                                style={{ background: "#7fb401" }}
                              >
                                Know More
                              </button>
                            </a>
                          </div>
                          <div className={`${styles.desktopBtn}`}>
                            {product?.amazon_link !== null &&
                              product?.amazon_link !== "" &&
                              product?.amazon_link !== "null" && (
                                <Link
                                  className={`${styles.desktopLogo}`}
                                  to={product?.amazon_link}
                                >
                                  <img
                                    className={`${styles.desktopLogoImage1}`}
                                    src={amazonImg}
                                    alt="amazon logo"
                                  />
                                </Link>
                              )}

                            {product?.flipkart_link !== null &&
                              product?.flipkart_link !== "" &&
                              product?.flipkart_link !== "null" && (
                                <Link
                                  to={product?.flipkart_link}
                                  className={`${styles.desktopLogo}`}
                                >
                                  <img
                                    className={`${styles.desktopLogoImage2}`}
                                    src={flipkartImg}
                                    alt="flipkart logo"
                                    srcset=""
                                  />
                                </Link>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className={styles.mobileBtn}>
                        <Link
                          to={`/product-detail/` + productslug(product.slug)}
                        >
                          <button
                            className="btn btn-primary ml-2"
                            style={{ background: "#7fb401 shopNowBtn" }}
                          >
                            Shop Now
                          </button>
                        </Link>
                        <a href={product?.product_pdf} target="_blank">
                          <button
                            className="btn btn-primary ml-2"
                            style={{ background: "#7fb401 knowMoreBtn" }}
                          >
                            Know More
                          </button>
                        </a>
                        {product?.amazon_link !== null &&
                          product?.amazon_link !== "" &&
                          product?.amazon_link !== "null" && (
                            <Link
                              to={product?.amazon_link}
                              className={`${styles.desktopLogo}`}
                            >
                              <img
                                className={`${styles.desktopLogoImage1}`}
                                src={amazonMobileImg}
                                alt="amazonMobileImg"
                                srcset=""
                              />
                            </Link>
                          )}
                        {product?.flipkart_link !== null &&
                          product?.flipkart_link !== "" &&
                          product?.flipkart_link !== "null" && (
                            <Link
                              to={product?.flipkart_link}
                              className={`${styles.desktopLogo}`}
                            >
                              <img
                                className={`${styles.desktopLogoImage1}`}
                                src={amazonMobileImg}
                                alt="amazonMobileImg"
                                srcset=""
                              />
                            </Link>
                          )}
                      </div>
                    </div>
                  </>
                ))}
              </>
            ) : (
              "NO Product found"
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(ProductDinamicPage); // Memoize the ProductDinamicPage;
