import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import TopNavBar from "../../layouts/TopNavBar";
import AlertContext from "../../../../context/Alert/AlertContext";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import classes from "./addFaqProductStyle.module.css";
import { errorToast } from "../../common/Toaster";
import { GrAddCircle, GrSubtractCircle } from "react-icons/gr";
import AuthContext from "../../../../context/Auth/AuthContext";
import LoaderContext from "../../../../context/Loader/LoaderContext";

// Validation rules
const validationRules = {
  product_id: Yup.string().required("Product is required"),
  faq: Yup.array()
    .of(
      Yup.object().shape({
        question: Yup.string().required("Question is required"),
        answer: Yup.string().required("Answer is required"),
      })
    )
    .min(1, "At least one row is required"),
};

const AddFaqProduct = () => {
  const { setAlertMessage } = useContext(AlertContext);
  const { authPermissions } = useContext(AuthContext);
  const navigate = useNavigate();
  const { setLoaderCheck } = useContext(LoaderContext);
  const [products, setProducts] = useState([]);
  const [faq, setFaq] = useState([{ question: "", answer: "" }]);

  const { id } = useParams();

  // Get Product List
  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get("products")
      .then((response) => {
        setLoaderCheck(false);
        setProducts(response?.data?.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
        errorToast(error.message);
      });
  }, []);

  useEffect(() => {
    if (id) {
      setLoaderCheck(true);
      axiosPrivate
        .get(`product-faq/${id}`)
        .then((response) => {
          setLoaderCheck(false);

          // Ensure the entire structure remains intact
          const finalData = response?.data?.data

          formik.setFieldValue("product_id", finalData[0]?.product_id);
          formik.setFieldValue("faq", finalData[0]?.faqs);
        })
        .catch((error) => {
          setLoaderCheck(false);
          errorToast(error.message);
        });
    }
  }, [id]);

  // Formik setup
  const formik = useFormik({
    initialValues: {
      product_id: "",
      faq: faq,
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: (values) => {
      const requetPayload = {
        product_id: values.product_id,
        faq: values.faq,
      };
      setLoaderCheck(true);
      if (id) {
        axiosPrivate
          .put(`product-faq/update/${id}`, requetPayload)
          .then((response) => {
            setLoaderCheck(false);
            setAlertMessage({
              message: "FAQ product updated successfully",
            });
            navigate(`/admin/faq-products`);
          })
          .catch((error) => {
            setLoaderCheck(false);
            if (error.response) {
              if (error.response.data.errors.length === undefined) {
                errorToast(error.response.data.errors.msg);
              } else {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                  errorToast(error.response.data.errors[i].msg);
                }
              }
            }
          });
      } else {
        axiosPrivate
          .post("product-faq", requetPayload)
          .then((response) => {
            setLoaderCheck(false);
            setAlertMessage({
              message: "FAQ product created successfully",
            });
            navigate(`/admin/faq-products`);
          })
          .catch((error) => {
            setLoaderCheck(false);
            if (error.response) {
              if (error.response.data.errors.length === undefined) {
                errorToast(error.response.data.errors.msg);
              } else {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                  errorToast(error.response.data.errors[i].msg);
                }
              }
            }
          });
      }
    },
  });

  const addRow = () => {
    const newRow = {
      question: "",
      answer: "",
      variants: [],
    };
    // Directly update Formik's faq using setFieldValue
    formik.setFieldValue("faq", [...formik.values.faq, newRow]);
  };

  const removeRow = (index) => {
    const newfaq = formik.values.faq.filter((_, i) => i !== index);
    // Directly update Formik's faq using setFieldValue
    formik.setFieldValue("faq", newfaq);
  };

  return (
    <>
      <TopNavBar
        links={{
          list: "/admin/faq-products",
          add: "/admin/faq-products/add",
        }}
      />
      <div className="section-body">
        <div className="container-fluid">
          <div className="tab-content">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="product-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-header">
                    <strong>
                      {id ? "Edit FAQ Product" : "Add FAQ Product"}
                    </strong>
                  </div>
                  <div className="card-body">
                    <div className="row clearfix">
                      <div className="col-md-5 col-sm-12">
                        <div className="form-group">
                          <label className="form-label">
                            Product <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            id="productId"
                            name="product_id"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.product_id}
                          >
                            <option>Select Product</option>
                            {products?.map((product, key) => (
                              <option
                                key={key + product?.name}
                                value={product.id}
                              >
                                {product?.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.product_id &&
                          formik.errors.product_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.product_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Table data */}
                      {formik?.values?.faq.map((row, index) => (
                        <div key={index} className="col-md-12 mb-3">
                          <div className="form-row">
                            <div className="col-md-5 col-sm-12">
                              <label className="form-label">
                                Question{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                             
                              <input
                                id="question"
                                name={`faq[${index}].question`}
                                type="text"
                                className="form-control"
                                placeholder="Question *"
                                value={row.question}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.faq?.[index]?.question &&
                                formik.errors.faq?.[index]?.question && (
                                  <div className={classes.invalidDataError}>
                                    {formik.errors.faq[index].question}
                                  </div>
                                )}
                            </div>

                            <div className="col-md-5 col-sm-12">
                              <label className="form-label">
                                Answer{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                id="answer"
                                name={`faq[${index}].answer`}
                                type="text"
                                className="form-control"
                                placeholder="Answer *"
                                value={row.answer}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.faq?.[index]?.answer &&
                                formik.errors.faq?.[index]?.answer && (
                                  <div className={classes.invalidDataError}>
                                    {formik.errors.faq[index]?.answer}
                                  </div>
                                )}
                            </div>

                            <div className={classes.actionBtns}>
                              <label className="form-label">&nbsp;</label>
                              <button
                                className={`btn ${classes.addBtn}`}
                                onClick={addRow}
                              >
                                <GrAddCircle />
                              </button>
                              {formik.values.faq.length > 1 && (
                                <button
                                  className={`btn ${classes.removeBtn}`}
                                  onClick={() => removeRow(index)}
                                >
                                  <GrSubtractCircle />
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="col-12 text-right">
                        <hr className="mt-4" />
                        <button
                          type="button"
                          id="button_1"
                          className="btn btn-secondary mx-1"
                          data-dismiss="modal"
                          onClick={() => navigate("/admin/faq-products")}
                        >
                          CLOSE
                        </button>
                        {authPermissions.includes("Product-create") && (
                          <button
                            type="submit"
                            id="button_2"
                            className="btn btn-primary"
                          >
                            SUBMIT
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFaqProduct;
