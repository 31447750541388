import React, { useEffect, useState } from "react";
import ProductDetail from "./ProductDetail";
import RecommendedProduct from "./RecommendedProduct";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { useParams } from "react-router-dom";
import LoaderContext from "../../../context/Loader/LoaderContext";
import { useContext } from "react";
import "./ProductDetailMain.css";
import FaqSection from "./FaqSection";
import ProductReviewSection from "./ProductReviewSection";

function ProductDetails() {
  const [product, setProduct] = useState([]);
  const [Details, setDetails] = useState([]);
  const { setLoaderCheck } = useContext(LoaderContext);

  const slug = useParams();

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    const options = {
      headers: { "content-type": "application/json" },
    };
    setLoaderCheck(true);
    axiosPrivate
      .get(`/products/slug/${slug.slug}`, options)
      .then((product) => {
        setLoaderCheck(false);
        setDetails(product.data.data);
        setProduct(product.data.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
      });
  }, [slug]);
  
  

  return (
    <div className="productDetailsMainContainer px-2">
      <div className="container">
      <ProductDetail product={product} />
      </div>

      {/* <div
        className=' md-custom-mx-5 md-custom-p-1'
        dangerouslySetInnerHTML={{ __html: product?.description }}
      /> */}
      {/* <ProductQuality /> */}

      {/* <Instruction /> */}
      <ProductReviewSection product={product}/>

      <div className="container">
      <RecommendedProduct Details={Details} />
      
      </div>
      <div className="container">
      {
        product?.product_faqs && <FaqSection product_faqs = {product?.product_faqs}/>
      }
      
      </div>
    </div>
  );
}

export default React.memo(ProductDetails); // Memoize the   ProductDetails;
