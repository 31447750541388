import React, { useEffect } from "react";
import DisSection1 from "./DisSection1";
import IndiaMap from "./IndiaMap";
import DistributorForm from "./DistributorForm";

const DistributorPage = (pageData) => {
  return (
    <div className="container py-4 px-3">
      <DisSection1 pageData = {pageData.pageData}  />
      <IndiaMap />
      <DistributorForm />
    </div>
  );
};

export default React.memo(DistributorPage); // Memoize the DistributorPage;
