import React from "react";
import styles from "./Gallery.module.css";
import { useState, useEffect } from "react";
import axiosPrivate from "../../../hooks/axiosPrivate";
import LoaderContext from "../../../context/Loader/LoaderContext";
import { useContext } from "react";
import { showErrorToast } from "../../../Toster";

function ProductGallery() {
  const [gallery, setGallery] = useState([]);

  const { setLoaderCheck } = useContext(LoaderContext);

  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get("product-gallery")
      .then((res) => {
        setLoaderCheck(false);
        setGallery(res.data.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (error.response) {
          showErrorToast("Internal Server Error");
        }
      });
  }, []);

  return (
    <div>
      <div className={`${styles.galleryContainer}`}>
        <div className={`${styles.outerContainer} container px-3`}>
          <h1 className={styles.mainHeading}>
            Our Farming Solutions Journey
            <img
              src="../assets/images/home/leaf-1-1 1.webp"
              alt="Leaf"
              loading="lazy"
              className={styles.leaf}
            />
          </h1>
          <div className={`${styles.imageContainer} row`}>
            {gallery
              ?.filter((data) => data.media_for == "Gallery")
              .map((image, index) => {
                let mediaType = image.media.split(".");
                if (
                  mediaType[1] == "jpg" ||
                  mediaType[1] == "webp" ||
                  mediaType[1] == "png"
                ) {
                  return (
                    <div
                      key={index}
                      className={`${styles.image} col-lg-4 col-sm-6 col-12 my-2`}
                    >
                      <img
                        src={
                          process.env.REACT_APP_COMMON_FILE_URL +
                          "product-gallery/" +
                          image.media
                        }
                        alt={image.alt}
                        loading="lazy"
                        className={styles.galleryContent}
                      />
                    </div>
                  );
                }
              })}
          </div>
        </div>

        <div className={styles.videoContainer}>
          <img
            src="../assets/images/home/Group 244.webp"
            className={styles.background}
            alt="background"
            loading="lazy"
          />
          <div className="container position-relative">
          <h2 className={`${styles.subHeading}`}>
            Chemical Free Solutions For Harmless Farming !
          </h2>
          <img
            src="../assets/images/home/leaf-1-1 1.webp"
            alt="Leaf"
            loading="lazy"
            className={styles.leaf3}
          />
          <div className={`${styles.imageContainer} row`}>
            {gallery
              ?.filter((data) => data.media_for == "Gallery")
              .map((image, index) => {
                let mediaType = image.media.split(".");
                if (mediaType[1] == "mp4") {
                  return (
                    <div
                      key={index}
                      className={`${styles.image} col-lg-4 col-sm-6 col-12 my-2`}
                    >
                      <video controls className={styles.galleryContent}>
                        <source
                          src={
                            process.env.REACT_APP_COMMON_FILE_URL +
                            "product-gallery/" +
                            image.media
                          }
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  );
                }
              })}
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ProductGallery); // Memoize the ProductGallery
