import React, { useState } from "react";
import styles from "./WhatsappLogo.module.css";
import { BsWhatsapp } from "react-icons/bs";
import { IoCaretDownSharp, IoCloseSharp } from "react-icons/io5";

const WhatsappLogo = () => {
  const [visible, setVisible] = useState(true);

  const createWhatsAppURL = (phoneNumber) => {
    // Check if the user is on a mobile device
    const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    const whatsappUrl = isMobile
      ? `https://wa.me/${phoneNumber}` // Mobile app
      : `https://web.whatsapp.com/send?phone=${phoneNumber}`; // WhatsApp Web
    return whatsappUrl;
  };

  const phoneNumber = "7574011099";

  const handleClose = () => {
    // First, immediately hide the container by adding the hide class
    document.getElementById("whatsappContainer").classList.add(styles.hide);

    // Then, after a slight delay, setVisible to false
    setTimeout(() => {
      setVisible(false);
    }, 300); // Adjust the time based on your animation duration
  };

  const handleAnimationEnd = () => {
    setVisible(false);
  };

  return (
    <div className={styles.whatsAppMainContainer}>
      {visible && (
        <div
          id="whatsappContainer"
          className={`${visible ? styles.show : ""}`}
          onAnimationEnd={handleAnimationEnd}
        >
          <div className={styles.chatWithUs}>Chat With Us</div>
          <div className={styles.downArrow}>
            <IoCaretDownSharp color="#FF5100" />
          </div>
          <div className={styles.closeIcon} onClick={handleClose}>
            <IoCloseSharp />
          </div>
        </div>
      )}
      {/* WhatsApp Floating Button (Always Visible) */}
      <a
        href={createWhatsAppURL(phoneNumber)}
        className={styles.float}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="/assets/images/home/WhatsappLogo.svg"
          alt="WhatsApp"
          className={styles["prevent-color-change"]}
        />
        {/* <BsWhatsapp className={styles["prevent-color-change"]} /> */}
      </a>
    </div>
  );
};

export default React.memo(WhatsappLogo);
