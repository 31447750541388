import React, { useEffect, useMemo, useState } from "react";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { Pagination, Search, TableHeader } from "../DataTable";
import Header from "../Header";

const DataTable2 = ({ lists, headers, searchItems }) => {
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const filterListLengths = [10, 25, 50, 100];
  let [color, setColor] = useState("#ffffff");


  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  useEffect(() => {
    setComments(lists);
  }, [lists]);

  const listData = useMemo(() => {
    let computedData = comments;

    if (search && searchItems.length > 0) {
      computedData = computedData.filter((filteData) =>
        searchItems.some((field) =>
          filteData[field]?.toLowerCase().includes(search?.toLowerCase())
        )
      );
    }

    setTotalItems(computedData.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedData.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage
    );
  }, [comments, currentPage, search, sorting, itemsPerPage]);

  const changeItemsPerPage = (e) => {
    setItemsPerPage(e.target.value);
  };

  return (
    <>
      <div className="row w-100">
        <div className="col mb-3 col-12 text-center">
          <div className="row">
            <div className="col-md-6">
              <div className="float-left  ml-3">
                <label className="mr-2">Show</label>
                <select
                  name="list_length"
                  className="form-select"
                  onChange={changeItemsPerPage}
                >
                  {filterListLengths.map((value, key) => {
                    return (
                      <option key={++key} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </select>
                <span className="ml-2">entries</span>
              </div>
            </div>
            <div className="col-md-6 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>

          {/* Table */}
      <div className="row w-100">
        <div className="col mb-3 col-12 text-center">
          {listData.length > 0 ? (
            <>
              <div className="table-container">
                <table className="table table-striped table-hover table-vcenter text-nowrap mb-0">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      {headers.map((header) => (
                        <th key={header.field}>{header.name}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {listData.map((comment, idx) => (
                      <tr key={idx}>
                        <td>{(currentPage - 1) * itemsPerPage + idx + 1}</td>
                        {headers.map((col, colIdx) => (
                          <td key={`${idx}-${colIdx}`}>{comment[col.field]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
            total={totalItems}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
              </div>
            </>
          ) : (
            <div className="alert alert-warning" role="alert">
              No data found.
            </div>
          )}
        </div>
      </div>


         
        </div>
      </div>
    </>
  );
};

export default DataTable2;
